<template>
  <div class="">
      <div class="d-block">
        <b-img :src="require(`@/assets/images/spore-icons/${img}.svg`)" class="grid-img" alt=""/>
      </div>
      <div class="d-block mt-2">
          <div class="device-text-container">
              <span class="main-info" v-if="name">
                  {{ name }}
              </span>
              <span class="subtext-info">
                  {{description}}
              </span>
          </div>
          <div class="device-text-container" v-if="name2 !== undefined && name2 !== ''">
              <span class="main-info" v-if="name">
                  {{ name2 }}
              </span>
              <span class="subtext-info">
                  {{description2}}
              </span>
          </div>
      </div>
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  props: {
    img: String,
    name: String,
    description: String,
    name2: String,
    description2: String
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/gridPrincipalItem.scss";
</style>
