<template>
  <div>
      <div class="device-info">
          <div class="container-img" v-if="img !== undefined">
            <b-img :src="getImgUrl(img)" class="info-icon" alt="ícono sensor"/>
          </div>
          <div class="device-text-container" v-if="!relativeTime">
              <span class="main-info">
                  {{ value }} <span class="unit">{{unit}}</span>
              </span>
              <span class="subtext-info">
                  {{description}}
              </span>
          </div>
          <div class="device-text-container" v-if="relativeTime">
              <span class="main-info">
                  {{relativeTimeConvert(value)}}
              </span>
          </div>
      </div>
  </div>
</template>
<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    BImg
  },
  props: {
    value: String,
    unit: String,
    img: String,
    description: String,
    min: {
      type: String,
      default: ''
    },
    max: {
      type: String,
      default: ''
    },
    relativeTime: Boolean
  },
  methods: {
    getImgUrl (name) {
      const val = parseFloat(this.value)
      const min = parseFloat(this.min)
      const max = parseFloat(this.max)
      const images = require.context('@/assets/images/spore-icons/', false, /\.svg$/)
      if (this.min !== '' && this.max !== '') {
        let color = ''
        if (val > max || val < min) color = 'red'
        else color = 'blue'
        return images(`./${name}_${color}.svg`)
      } else {
        return images(`./${name}.svg`)
      }
    },
    relativeTimeConvert (date) {
      date.replace(' ', 'T')
      return this.$dayjs().to(this.$dayjs(date))
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/spore-components/valueItem";
</style>
