<style>
  .container-items{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    justify-content: center;
  }
  .leaflet-popup-content{
    width: 300px!important;
  }

  .filter-container{
    width: 245px;
  }

  .maps-container {
    height: 700px;
    width: 100%;
  }

  .vue2leaflet-map .leaflet-shadow-pane {
    display: none !important;
  }

  .leaflet-popup-content{
    width: 200px;
  }
</style>
<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <div class="maps-container" v-if="status_mapa">
            <l-map style="height: 100%; overflow: hidden;" :zoom="zoom" :center="center" ref="mymap" :options="{attributionControl: false}">
              <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
              <l-control-layers
                :position="layersPosition"
                :collapsed="true"
                :sort-layers="true"
              />
              <l-control :position="layersPosition">
                <b-button variant="primarySpore" style="background:#fff;" class="btn-icon rounded-circle" @click="showFiltrar = !showFiltrar" v-b-tooltip.hover.left :title="!showFiltrar ? 'Mostrar Filtros' : 'Ocultar Filtros'">
                  <feather-icon icon="FilterIcon" />
                </b-button>

                <div v-if="showFiltrar" class="filter-container bg-white border-2 border-solid border-grey py-4 px-2 rounded-lg mt-1">
                  <div class="font-bold mb-2 text-center">
                    <b>Filtrar Por:</b>
                  </div>
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Número Gabinete"
                      >
                        <b-form-input
                          v-model= "numberGaninets"
                          placeholder="Escribir número de gabinete"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Serie"
                      >
                        <b-form-input
                          v-model= "serie"
                          placeholder="Escribir la serie"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12">
                      <b-form-group
                        label="Estatus"
                      >
                        <v-select
                        placeholder= "seleccionar status"
                        v-model="status"
                        :options="opcStatus"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col md="12" class="text-center">
                      <b-button
                        variant="primarySpore"
                        @click="getMapa"
                      >
                        <feather-icon
                          icon="SearchIcon"
                          class="mr-50"
                        />
                        <span class="align-middle">Buscar</span>
                      </b-button>
                    </b-col>
                  </b-row>
                </div>
              </l-control>
              <l-control :position="layersPosition">
                <b-button variant="info" style="background:#fff;" class="btn-icon rounded-circle" :href="urlDownload" download="map.kml" v-b-tooltip.hover.left title="Descargar .kml">
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>
              </l-control>
              <l-control :position="layersPosition">
                <b-button variant="info" style="background:#fff;" class="btn-icon rounded-circle" :href="urlDownloadCsv" download="Gabinetes_detalle.csv" v-b-tooltip.hover.left title="Descargar .csv">
                  <feather-icon icon="DownloadIcon" />
                </b-button>
              </l-control>
              <l-tile-layer
                v-for="tileProvider in tileProviders"
                :key="tileProvider.name"
                :name="tileProvider.name"
                :visible="tileProvider.visible"
                :url="tileProvider.url"
                layer-type="base"
              />
              <v-marker-cluster>
                <l-marker
                  v-for="marker in markers"
                  :key="marker.id"
                  :visible="marker.visible"
                  :draggable="marker.draggable"
                  :lat-lng.sync="marker.position"
                >
                  <l-icon :icon-size="dynamicSize" :icon-anchor="dynamicAnchor" >
                    <b-img
                      style="width: 30px !important"
                      v-if="!isEmpty(marker.date) && marker.transmitting === 1"
                      rounded
                      :src="marker.status === 'N' ? require(`@/assets/images/markers/success.svg`) : require(`@/assets/images/markers/warning.svg`)"
                    />
                    <b-img
                      style="width: 30px !important"
                      v-if="!isEmpty(marker.date) && marker.transmitting === 0"
                      rounded
                      :src="require(`@/assets/images/markers/danger.svg`)"
                    />
                    <b-img
                      style="width: 30px !important"
                      v-if="isEmpty(marker.date)"
                      rounded
                      :src="require(`@/assets/images/markers/11.svg`)"
                    />
                  </l-icon>

                  <l-popup>
                    <gridPrincipalItem
                      img="energy"
                      name=""
                      description=""
                    ></gridPrincipalItem>
                    <valueItem
                      :value="marker.serie"
                      description="Serie"
                      img="alias"
                    ></valueItem>
                    <valueItem
                      :value="marker.numberCabinet"
                      description="Número de gabinete"
                      img="alias"
                    ></valueItem>
                    <valueItem
                      :value="formatDate(marker.date)"
                      description="Fecha de transmisión"
                      img="fecha"
                    ></valueItem>
                    <div class="text-nowrap mb-1">
                      <b-avatar
                        v-if="marker.status"
                        size="32"
                        class="avatar-border-2 box-shadow-1"
                        :variant="marker.status === 'N' ? 'success' : 'warning'"
                      >
                        <feather-icon class="align-text-top" v-if="marker.status === 'N'" icon="CloudIcon" size="18" />
                        <feather-icon class="align-text-top" v-if="marker.status === 'M'" icon="CloudOffIcon" size="18"/>
                      </b-avatar>
                      <b>
                        <label class="ml-2" style="font-size:18px;" :class="marker.status === 'N' ? 'text-success' : 'text-warning'">{{marker.status === 'N' ? 'Seguro' : 'Mantenimiento' }}</label>
                      </b>
                    </div>
                    <div class="text-nowrap mb-1">
                      <b-avatar
                        v-if="marker.status"
                        size="32"
                        class="avatar-border-2 box-shadow-1"
                        :variant="(!isEmpty(marker.date) && marker.transmitting === 1 ? 'success' : (!isEmpty(marker.date) && marker.transmitting === 0 ? 'danger' : 'secondary'))"
                      >
                        <feather-icon class="align-text-top" v-if="!isEmpty(marker.date) && marker.transmitting === 1" icon="WifiIcon" size="18"/>
                        <feather-icon class="align-text-top" v-if="!isEmpty(marker.date) && marker.transmitting === 0" icon="WifiOffIcon" size="18" />
                        <feather-icon class="align-text-top" v-if="isEmpty(marker.date)" icon="WifiOffIcon" size="18"/>
                      </b-avatar>
                      <b>
                        <label class="ml-2" style="font-size:18px;" :class="(!isEmpty(marker.date) && marker.transmitting === 1 ? 'text-success' : (!isEmpty(marker.date) && marker.transmitting === 0 ? 'text-danger' : 'text-secondary'))">
                          {{ (!isEmpty(marker.date) && marker.transmitting === 1 ? 'Transmitiendo' : (!isEmpty(marker.date) && marker.transmitting === 0 ? 'Sin transmitir hace 24 hrs' : 'No ha transmitido')) }}
                        </label>
                      </b>
                    </div>
                    <div class="text-center">
                      <b-button
                      variant="primarySpore"
                      @click="deviceInformation(marker.uuid)">
                        Ver Más
                      </b-button>
                    </div>
                  </l-popup>
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormGroup, BAvatar, BImg, BFormInput, VBTooltip
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import { formatDateLanguages, empty } from '@/libs/tools/helpers'
import GeoJSON from 'geojson'
import tokml from 'geojson-to-kml'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LPopup,
  LControl,
  LControlLayers,
  LControlAttribution
} from 'vue2-leaflet'
import vSelect from 'vue-select'
import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster/Vue2LeafletMarkercluster'
import gridPrincipalItem from '@/@core/spore-components/gridPrincipalItem/gridPrincipalItem.vue'
import valueItem from '@/@core/spore-components/valueItem/valueItem.vue'
import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import 'leaflet-fullscreen/dist/Leaflet.fullscreen'
/* global L:true */

const tileProviders = [
  {
    name: 'Gris',
    visible: true,
    url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png'
  },
  {
    name: 'Calles',
    visible: false,
    url: 'https://{s}.tile.openstreetmap.fr/osmfr//{z}/{x}/{y}.png'
  },
  {
    name: 'Satelite',
    visible: false,
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
  }
]

export default {
  name: 'mapa',
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LControl,
    LPopup,
    LIcon,
    LControlLayers,
    LControlAttribution,
    'v-marker-cluster': Vue2LeafletMarkercluster,
    gridPrincipalItem,
    valueItem,
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    vSelect,
    BAvatar,
    BImg,
    BFormInput
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      iconSize: 50,
      filter: {
        type: ''
      },
      tileProviders,
      layersPosition: 'topright',
      center: [20.662396323989693, -103.37640969334421],
      zoom: 10,
      staticAnchor: [12, 27],
      response: [],
      markers: [],
      marker2: [],
      status_mapa: true,
      showFiltrar: false,
      allSelected: true,
      positions: [],
      URL,
      status: '',
      numberGaninets: '',
      serie: '',
      urlDownload: '',
      urlDownloadCsv: '',
      responseDataCsv: [],
      opcStatus: [
        {
          label: 'Seguro',
          value: 'N',
          title: 'Seguro'
        },
        {
          label: 'Mantenimiento',
          value: 'M',
          title: 'Mantenimiento'
        }
      ]
    }
  },
  computed: {
    dynamicSize () {
      return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor () {
      return [this.iconSize / 4, this.iconSize * 1.15]
    }
  },
  methods: {
    isEmpty (val) {
      return empty(val)
    },
    async getMapa () {
      const params = {
        url: '/cabinets/map/list',
        method: 'GET',
        params: {
          number: '',
          serie: '',
          status: !empty(this.status) ? this.status.value : ''
        }
      }
      await request(params).then(async response => {
        this.markers = []
        this.positions = []
        const positions1 = []
        this.responseDataCsv = []
        this.response = response.data.data
        this.status_mapa = true
        for (let index = 0; index < this.response.length; index++) {
          if (!empty(this.response[index].cfeCabinet.latlong)) {
            positions1.push([this.response[index].cfeCabinet.latlong.split(',')[0], this.response[index].cfeCabinet.latlong.split(',')[1]])
            this.addPosition(this.response[index])
            this.addMarker(this.response[index])

            this.responseDataCsv.push({
              name: this.response[index].cfeAgency.name,
              number: this.response[index].cfeCabinet.number,
              type: (this.response[index].cfeCabinet.type === 'P' ? 'Protecsa' : this.response[index].cfeCabinet.type === 'N' ? 'Nansen' : this.response[index].cfeCabinet.type === 'S' ? 'Siemens' : 'S/D'),
              street: (this.response[index].cfeCabinet.street ? this.response[index].cfeCabinet.street : 'S/D'),
              numExt: (this.response[index].cfeCabinet.numExt ? this.response[index].cfeCabinet.numExt : 'S/D'),
              suburb: (this.response[index].cfeCabinet.suburb ? this.response[index].cfeCabinet.suburb : 'S/D'),
              postalCode: (this.response[index].cfeCabinet.postalCode ? this.response[index].cfeCabinet.postalCode : 'S/D'),
              location: (this.response[index].cfeCabinet.location ? this.response[index].cfeCabinet.location : 'S/D'),
              municipality: (this.response[index].cfeCabinet.municipality ? this.response[index].cfeCabinet.municipality : 'S/D'),
              state: (this.response[index].cfeCabinet.state ? this.response[index].cfeCabinet.state : 'S/D'),
              latlong: (this.response[index].cfeCabinet.latlong ? this.response[index].cfeCabinet.latlong : 'S/D')
              // meters: this.response[index].cfeMeters.length
            })
          }
        }
        const app = this
        this.exportar()
        this.createCsv()
        setTimeout(function () {
          const maps = app.$refs.mymap.mapObject
          if (!this.fullscreen) {
            maps.addControl(new L.Control.Fullscreen({
              position: 'topleft'
            }))
            this.fullscreen = true
          }
          maps.fitBounds(positions1)
          app.showButtons = true
        }, 1000)
      })
    },
    exportar () {
      const res = GeoJSON.parse(this.marker2, { Point: ['lat', 'lng'] })
      const kml = tokml(res)
      const file = new Blob([kml], { type: 'text/kml' })
      console.log(file)
      this.urlDownload = window.URL.createObjectURL(file)
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push('Agencia')
      headers.push('Número gabinete')
      headers.push('Tipo')
      headers.push('Calle')
      headers.push('Num exterior')
      headers.push('Colonia')
      headers.push('Codigo postal')
      headers.push('Localidad')
      headers.push('Municipio')
      headers.push('Estado')
      headers.push('Longitud/Latitud')
      // headers.push('Cant Medidores')
      csv.push(headers.join(','))
      this.responseDataCsv.map(item => {
        const row = []
        row.push(item.name)
        row.push(item.number)
        row.push(item.type)
        row.push(item.street)
        row.push(item.numExt)
        row.push(item.suburb)
        row.push(item.postalCode)
        row.push(item.location)
        row.push(item.municipality)
        row.push(item.state)
        row.push(item.latlong)
        // row.push(item.meters)
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownloadCsv = window.URL.createObjectURL(csvFile)
    },
    deviceInformation (uuid) {
      this.$router.push(`gabinets/${uuid}/detail`)
    },
    addPosition (element) {
      this.positions.push([element.cfeCabinet.latlong.split(',')[0], element.cfeCabinet.latlong.split(',')[1]])
    },
    addMarker (element) {
      this.markers.push({
        position: { lat: element.cfeCabinet.latlong.split(',')[0], lng: element.cfeCabinet.latlong.split(',')[1] },
        serie: element.serie,
        id: element.uuid,
        date: element.transmitedAt,
        status: element.status,
        numberCabinet: element.cfeCabinet.number,
        uuid: element.uuid,
        transmitting: element.transmitting,
        draggable: false,
        visible: true
      })
      this.marker2.push(
        {
          serie: element.serie,
          name: element.cfeCabinet.number,
          lat: element.cfeCabinet.latlong.split(',')[0],
          lng: element.cfeCabinet.latlong.split(',')[1]
        }
      )
    },
    fitBounds () {
      const maps = this.$refs.mymap.mapObject
      maps.fitBounds(this.positions)
    },
    formatDate (date) {
      return formatDateLanguages(date, 'hour')
    }
  },
  created () {
    this.getMapa()
  }
}
</script>
<style lang="scss">
  @import "~leaflet.markercluster/dist/MarkerCluster.css";
  @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
